import React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import { buildClass, checkClass } from '../../../utils/tag'
import { Text } from '../../../utils/helper'
import { useTranslation } from 'react-i18next'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = (dir) => ({
    PaperProps: {
        style: {
            direction: dir,
            backgroundColor: '#C7C9D2',
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
})

export default function SelectInput({
    label,
    error,
    options,
    className,
    helperText,
    ...otherProps
}) {
    const { i18n } = useTranslation();
    const dir = i18n.language

    return (
        <div
            className={buildClass('flex flex-col gap-2 w-full relative', className)}>
            {label && <Text className="!text-[#3C3C3B]">{label}</Text>}
            <FormControl fullWidth>
                <Select
                    sx={{
                        width: "100%",
                        py: '0px',
                        height: '60px',
                        color: '#C7C9D2',
                        '& div': {
                            py: '0px'
                        },
                        '& svg': {
                            fill: '#C7C9D2'
                        }
                    }}
                    MenuProps={MenuProps(dir)}
                    className={buildClass(
                        'input input-xs text-[#373f4b] h-[60px] text-sm',
                        ' w-full rounded-[5px] !border-[1px] bg-transparent',
                        'placeholder:text-[#373f4b] placeholder:text-sm',
                        { '!text-[#373f4b]': otherProps?.disabled },
                        checkClass(error, [
                            'border-[#f00]',
                            'border-[#D1D1D1]'
                        ]),
                        className
                    )}
                    {...otherProps}
                >
                    {options.map(({ label, value }) => (
                        <MenuItem value={value} key={value}>
                            <Text className={buildClass("!text-[#373f4b]")} size="18px">{label}</Text>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {error && <Text className="!text-[#f00]" size="14px">{helperText}</Text>}
            {otherProps?.disabled ? <div onClick={() => {
                otherProps?.onDisabled && otherProps.onDisabled()
            }}
                className='absolute w-full h-full' /> : null}
        </div>
    )
}
