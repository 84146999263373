import React from 'react'
import { useTranslation } from 'react-i18next';
import { buildClass } from '../utils/tag';

export default function Navbar() {
    const { t, i18n } = useTranslation('common');
    const isAr = i18n.language === 'ar'

    const handleLangChange = () => {
        i18n.changeLanguage(isAr ? 'en' : 'ar')
    };

    return (<div className='relative flex justify-center max-h-[470px] h-[470px]'>
        <div className="navbar bg-transparent absolute py-3 px-7 max-w-[1526px]">
            <div className="flex-1">
                <a className="text-xl">
                    <img src="/img/logo.png" />
                </a>
            </div>
            <div className="flex-none">
                <button onClick={handleLangChange}
                    className="flex flex-col items-center justify-center gap-1">
                    <img src="/img/lang.png" />
                    <span className="text-sm !text-white">
                        {t("lang")}
                    </span>
                </button>
            </div>
        </div>
        <div className={buildClass("w-full h-full",
            "sm:bg-[url('/public/img/background.png')] bg-[url('/public/img/background-sm.png')]")}
        />
    </div>)
}
