import yup from '../../lib/yup'
import { useForm } from 'react-hook-form'
import { buildClass } from '../../utils/tag'
import { useTranslation } from 'react-i18next'
import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import http, { handleErrorResponse } from '../../utils/network'
import InputController from '../form-controls/controllers/InputController'
import SelectController from '../form-controls/controllers/SelectController'

export const Schema = yup.object().shape({
    FirstName: yup.string().required().min(2).max(15)
        .test('FirstName', "charactersOnly", (val) => val?.match(/^[a-z,ء-ي," "]*$/gmi))
        .test('FirstName', "nullable", (val) => val.trim() !== ""),
    LastName: yup.string().required().min(2).max(15)
        .test('LastName', "charactersOnly", (val) => val?.match(/^[a-z,ء-ي," "]*$/gmi))
        .test('LastName', "nullable", (val) => {
            return val.trim() !== ""
        }),
    MobileNumber: yup.string()
        .min(8, { key: 'phone', values: { num: 8 } })
        .max(8, { key: 'phone', values: { num: 8 } })
        .required(),
    IssueDescription: yup.string().required()
        .test('IssueDescription', "nullable", (val) => {
            return val.trim() !== ""
        }),
    GateId: yup.number().typeError("nullable").nullable().required(),
    LocationId: yup.number().typeError("nullable").nullable().required(),
    SpecialClassificationId: yup.number().typeError("nullable").nullable().required(),
})

export default function FormBody({ setResult }) {
    const { t, i18n } = useTranslation('common')
    const [loading, setLoading] = useState(false)
    const [locOptions, setLocOptions] = useState([])
    const [gateOptions, setGateOptions] = useState([])
    const [specialOptions, setSpecialOptions] = useState([])

    const { executeRecaptcha } = useGoogleReCaptcha();

    const { control, handleSubmit, trigger, reset, watch, setError } = useForm({
        resolver: yupResolver(Schema)
    })

    const { LocationId } = watch()
    const isAr = i18n.language === "ar"

    const handleSubmition = async (values) => {
        if (loading) return
        setLoading(true)
        try {
            const recaptchaToken = await executeRecaptcha()
            values = {
                ...values,
                recaptchaToken,
                MobileNumber: `05${values?.MobileNumber}`,
                PreferredLanguage: isAr ? "Arabic" : "English"
            }

            const res = await http.post(`/v1/crm-services/smart-parking/incidents`, values);
            const pyID = res?.data?.data?.result?.pyID
            if (pyID) {
                setResult({ state: "success", id: pyID, reset })
            }

            setLoading(false);
        } catch (error) {
            const message = handleErrorResponse(error)
            setResult({ state: "faild", message })
            setLoading(false)
        }
    }

    useEffect(() => {
        const getDDL = async () => {
            try {
                const resLocations = await http.get(`/v1/crm-services/smart-parking/locations`)
                const resClassifications = await http.get(`/v1/crm-services/smart-parking/specialClassifications`)

                const reBuildLocations = (resLocations?.data || []).map(({ id,
                    nameAr,
                    nameEn }) => ({
                        label: isAr ? nameAr : nameEn,
                        value: id
                    }))
                const reBuildClassifications = (resClassifications?.data || []).map(({ id,
                    nameAr,
                    nameEn }) => ({
                        label: isAr ? nameAr : nameEn,
                        value: id
                    }))

                setLocOptions(reBuildLocations)
                setSpecialOptions(reBuildClassifications)
            } catch (error) {
                console.error(error);
            }
        }
        getDDL()
    }, [isAr])

    useEffect(() => {
        const getGates = async () => {
            if (!LocationId) return
            try {
                const resGates = await http.get(`/v1/crm-services/smart-parking/gates/?locationId=${LocationId}`)
                const reBuildGates = (resGates?.data || []).map(({ id,
                    nameAr,
                    nameEn }) => ({
                        label: isAr ? nameAr : nameEn,
                        value: id
                    }))
                setGateOptions(reBuildGates)
            } catch (error) {
                console.error(error);
            }
        }
        getGates()
    }, [isAr, LocationId])

    return (<>
        <div className="flex items-center gap-3">
            <div className='flex items-center justify-center bg-[#84BC471A] w-12 h-12 rounded-full'>
                <img src="/img/ticket.png" />
            </div>
            <h2 className="text-[#3C3C3B] text-2xl font-bold">
                {t('ticket.open')}
            </h2>
        </div>
        <h2 className="!text-[#0E615E] text-xl font-bold mb-3">
            {t('ticket.note')}
        </h2>
        <form
            className="flex flex-col w-full bg-white relative sm:gap-10 gap-5"
            onSubmit={handleSubmit(handleSubmition)}>
            <div className="flex gap-5 sm:flex-row flex-col">
                <InputController
                    trigger={trigger}
                    control={control}
                    name="FirstName"
                    label={<>{t('inputs.firstName.label')} <span className='text-[#f00]'>*</span></>}
                    placeholder={t('inputs.firstName.place')}
                    parentClass="sm:w-1/2"
                />
                <InputController
                    control={control}
                    trigger={trigger}
                    name="LastName"
                    label={<>{t('inputs.lastName.label')} <span className='text-[#f00]'>*</span></>}
                    placeholder={t('inputs.lastName.place')}
                    parentClass="sm:w-1/2"
                />
            </div>
            <div className="flex gap-5 sm:flex-row flex-col">
                <SelectController
                    trigger={trigger}
                    control={control}
                    name="SpecialClassificationId"
                    label={<>{t('inputs.typeOfProblem.label')} <span className='text-[#f00]'>*</span></>}
                    placeholder={t('inputs.typeOfProblem.place')}
                    options={specialOptions}
                    className="sm:w-1/2"
                />
                <div className="flex gap-1 flex-row sm:w-1/2">
                    <InputController
                        type="number"
                        trigger={trigger}
                        control={control}
                        name="MobileNumber"
                        label={<>{t('inputs.phoneNumber.label')} <span className='text-[#f00]'>*</span></>}
                        placeholder={t('xxxxxxxx')}
                        parentClass="w-full"
                        endIcon={<div className={buildClass(
                            'flex items-center justify-center w-[60px] h-[60px] absolute left-0',
                            'rounded-[5px] bg-[#84BC471A]'
                        )}>
                            <span className='text-2xl text-[#84BC47]'>05</span>
                        </div>}
                    />
                </div>
            </div>
            <div className="flex gap-5 sm:flex-row flex-col">
                <SelectController
                    trigger={trigger}
                    control={control}
                    name="LocationId"
                    label={<>{t('inputs.location.label')} <span className='text-[#f00]'>*</span></>}
                    placeholder={t('inputs.location.place')}
                    options={locOptions}
                />
                <SelectController
                    name="GateId"
                    trigger={trigger}
                    control={control}
                    options={gateOptions}
                    disabled={!Boolean(LocationId)}
                    placeholder={t('inputs.gate.place')}
                    label={<>{t('inputs.gate.label')} <span className='text-[#f00]'>*</span></>}
                    onDisabled={() => setError("GateId", { message: "chooseLocFirst", type: "client" })}
                />
            </div>
            <div className="flex gap-5 sm:flex-row flex-col">
                <InputController
                    rows={9}
                    multi={true}
                    trigger={trigger}
                    control={control}
                    name="IssueDescription"
                    label={<>{t('inputs.description.label')} <span className='text-[#f00]'>*</span></>}
                    placeholder={t('inputs.description.place')}
                    parentClass="w-full"
                />
            </div>
            <div className="flex gap-5 justify-end">
                <button type='submit'
                    className={buildClass("!border-none gap-5",
                        "btn w-[175px] h-[40px] !rounded-[8px]",
                        "hover:bg-[#368C8A] bg-[#368C8A] text-white")}>
                    {t('buttons.sendRequest')}{loading ? <CircularProgress size={24} color='inherit' /> : null}
                </button>
            </div>
        </form>
    </>)
}
