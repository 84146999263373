import React from 'react'
import SelectInput from '../helper/SelectInput'
import { Controller } from 'react-hook-form'
import HandleErrorMsg from '../helper/handleErrorMsg'

export default function SelectController({
    name,
    rules,
    control,
    trigger,
    defaultValue = '',
    ...otherProps
}) {
    return (
        <Controller
            name={name}
            rules={rules}
            control={control}
            render={({ field: { onChange, ...rest }, fieldState: { error } }) => {
                return (
                    <SelectInput
                        helperText={HandleErrorMsg(error)}
                        error={Boolean(error)}
                        onChange={(e) => {
                            onChange(e)
                            trigger(name)
                        }}
                        {...rest}
                        {...otherProps}
                    />
                )
            }}
            defaultValue={defaultValue}
        />
    )
}
