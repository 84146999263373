import React from 'react'
import { useTranslation } from 'react-i18next';
import { buildClass } from '../utils/tag';

export default function Footer() {
    const { t } = useTranslation('common');

    return (<footer
        className={buildClass(
            'flex min-h-[82px] w-full max-w-[1526px]',
            'justify-center items-center relative py-4 px-7 bg-[#043433]'
        )}>
        <p className='text-[#fff] sm:text-base text-xs text-center'>
            {t("footer.allRights")}
        </p>
    </footer>)
}
