import { useTranslation } from 'react-i18next';

export default function HandleErrorMsg(error) {
    let errMsg = null;
    const { t } = useTranslation("common");

    if (typeof error === 'object') {
        const { type, message } = error;
        if (type === 'serverSideError') {
            errMsg = message;
        } else {
            if (typeof message === 'object' && message.key && message.values) {
                errMsg = t(`validation.${message.key}`, { ...message.values });
            } else if (typeof message === 'string') {
                errMsg = t(`validation.${message}`);
            }
        }
    }

    return errMsg;
}