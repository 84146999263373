import React from 'react'
import { useTranslation } from 'react-i18next'
import { buildClass } from '../../utils/tag'

export default function Results({ result, setResult }) {
    const { t } = useTranslation('common')
    const isSuccess = result?.state === "success"

    return (<div className="flex flex-col gap-8 w-full items-center">
        <div className='flex items-center justify-center'>
            <img src={`/img/${isSuccess ? "done" : "close"}.png`} />
        </div>
        <div className="flex flex-col gap-2 w-full items-center justify-center">
            <h2 className="text-[#3C3C3B] sm:text-[32px] text-xl font-bold">
                {t(`result.${isSuccess ? "done" : "close"}.title`)}
            </h2>
            <p className='font-light sm:text-xl text-base text-[#737373] text-center'>
                {isSuccess ? t(`result.done.note`) : result?.message}
            </p>
        </div>
        {isSuccess ? <div className="flex flex-col gap-2 w-full items-center justify-center">
            <div style={{ border: "0.5px solid #D1D1D1" }}
                className="flex items-center gap-3 max-w-[376px] w-full rounded-[10px] p-5">
                <div className='flex items-center justify-center bg-[#DAEAE9] w-12 h-12 rounded-lg'>
                    <img src="/img/ticket-no.png" />
                </div>
                <div className="flex flex-col gap-1 w-auto">
                    <h2 className="text-[#3C3C3B] sm:text-base text-sm font-normal">
                        {t(`ticket.no`)}
                    </h2>
                    <p className='font-bold text-sm text-[#389492]' dir='ltr'>
                        #{result?.id}
                    </p>
                </div>
            </div>
        </div> : null}
        <button type='button'
            onClick={() => {
                const reset = result?.reset
                isSuccess && reset && reset({})
                setResult(null)
            }}
            className={buildClass("!border-none sm:text-lg text-base",
                "btn w-[175px] h-[40px] !rounded-[8px]",
                "hover:bg-[#368C8A] bg-[#368C8A] text-white")}>
            {t('buttons.gotoModel')}
        </button>
    </div>)
}