import { buildClass } from './tag'
import { CircularProgress } from '@mui/material'

export function Text({
    xs,
    className,
    width,
    size = '20px',
    color = '#fff',
    weight,
    top = '-3px',
    fontType = '"Ar-rmn"',
    children
}) {
    return (
        <span
            className={buildClass('relative', className)}
            style={{
                top,
                color,
                width,
                fontSize: size,
                fontWeight: weight,
                fontFamily: fontType,
                ...xs
            }}
        >
            {children}
        </span>
    )
}

export function SubmitBTN({ className, loading, children }) {
    return (
        <button
            type="submit"
            className={buildClass(
                ' bg-filter backdrop-blur-lg',
                'flex items-center justify-center rounded-[33px]',
                'w-full h-16 border-[1px] border-alva-100 relative',
                className
            )}
        >
            {loading ? <CircularProgress /> : children}
        </button>
    )
}