import React, { forwardRef } from 'react'
import { Text } from '../../../utils/helper'
import { buildClass, checkClass } from '../../../utils/tag'

const Input = forwardRef(
    (
        {
            name,
            className,
            error,
            helperText,
            label,
            multi,
            rows = 3,
            endIcon,
            parentClass,
            ...otherProps
        },
        ref
    ) => {
        return (
            <div className={buildClass('flex flex-col gap-2', parentClass)}>
                {label && <Text className="!text-[#3C3C3B]">{label}</Text>}
                {multi ? (
                    <div className="flex relative gap-1">
                        <textarea
                            name={name}
                            ref={ref}
                            rows={rows}
                            className={buildClass(
                                'text-[#373f4b] text-sm resize-none',
                                'textarea w-full rounded-[5px] border-[1px]',
                                'placeholder:text-[#373f4b] placeholder:text-sm',
                                checkClass(otherProps?.disabled, [
                                    '!text-[#373f4b]',
                                    'bg-transparent'
                                ]),
                                checkClass(error, [
                                    'border-[#f00]',
                                    'border-[#D1D1D1]'
                                ]),
                                className
                            )}
                            {...otherProps}
                        />
                    </div>
                ) : (
                    <div className="flex relative gap-1">
                        <input
                            name={name}
                            ref={ref}
                            className={buildClass(
                                'text-[#373f4b] text-sm border-[1px]',
                                'input input-xs w-full rounded-[5px] h-[60px]',
                                'placeholder:text-[#373f4b] placeholder:text-sm',
                                { 'pl-[65px]': Boolean(endIcon) },
                                checkClass(otherProps?.disabled, [
                                    '!text-[#373f4b]',
                                    'bg-transparent'
                                ]),
                                checkClass(error, [
                                    'border-[#f00]',
                                    'border-[#D1D1D1]'
                                ]),
                                className
                            )}
                            {...otherProps}
                        />
                        {endIcon}
                    </div>
                )}

                {error && <Text className="!text-[#f00]" size="14px">{helperText}</Text>}
            </div>
        )
    }
)

Input.displayName = 'Input'

export default Input
