import React from 'react'
import Input from '../helper/input'
import { Controller } from 'react-hook-form'
import HandleErrorMsg from '../helper/handleErrorMsg'

export default function InputController({
    name,
    control,
    rules,
    defaultValue = '',
    Icon = null,
    ref,
    trigger,
    errorPath,
    ...otherProps
}) {
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({
                field: { value, onChange, ...otherRenderProps },
                fieldState: { error }
            }) => {
                return (
                    <Input
                        helperText={HandleErrorMsg(error)}
                        error={Boolean(error)}
                        value={value === null ? '' : value}
                        InputProps={{
                            startAdornment: Icon
                        }}
                        onChange={(e) => {
                            onChange(e)
                            trigger(name)
                        }}
                        {...otherProps}
                        {...otherRenderProps}
                    />
                )
            }}
            defaultValue={defaultValue}
        />
    )
}
