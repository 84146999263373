import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormBody from './FormBody'
import Results from './Results'
import { buildClass } from '../../utils/tag'

export default function Body() {
    const { t } = useTranslation('common')
    const [result, setResult] = useState(null)

    return (<div className='relative flex justify-center -mt-[380px] mb-7'>
        <div className="flex flex-col justify-between py-4 px-7 w-full max-w-[1526px] h-auto gap-7">
            <div className="flex flex-col gap-5">
                <h2 className="text-[#3C9388] text-base font-normal">
                    <span className='font-bold'>{t('main.title')}</span>{" / "}{t('main.info')}
                </h2>
                <div className="flex items-center gap-3">
                    <div className='flex items-center justify-center bg-[#353535] w-12 h-12 rounded-lg'>
                        <img src="/img/info.png" />
                    </div>
                    <h2 className="text-[#fff] text-[32px] font-bold">
                        {t('header')}
                    </h2>
                </div>
            </div>
            <div
                className={buildClass(
                    'flex flex-col gap-5 w-full bg-[#fff] rounded-lg p-6',
                    { 'hidden': result !== null }
                )}>
                <FormBody setResult={setResult} />
            </div>
            <div
                className={buildClass(
                    'flex flex-col gap-5 w-full bg-[#fff] rounded-lg p-6',
                    { 'hidden': result === null }
                )}>
                {result === null ? null : <Results result={result} setResult={setResult} />}
            </div>
        </div>
    </div>)
}
