import axios from 'axios'

const handleClientError = (response, status) => {
    const msg = response.data.responseMessage;

    if (msg) {
        return `${msg} code: ${status}`
    } else {
        return handleServerError()
    }
};

const handleServerError = () => {
    return 'The service is unavailable now. Please try again later'
};

export const handleErrorResponse = (error) => {
    const response = error.response;
    const status = response.status;

    if (status === 401) {
        return "Not Ahuth!"
    }

    if (status >= 400 && status <= 499) {
        return handleClientError(response, status);
    }

    if (status >= 500 && status <= 599) {
        return handleServerError();
    }
};

const baseURL = "https://api.baladyparking.com/ahya"

const http = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json'
    }
})

export default http