import Body from './components/body';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation('common');
  const isAr = i18n.language === 'ar'

  return (<div style={{
    direction: isAr ? 'rtl' : 'ltr',
  }}
    className="flex flex-col gap-5 bg-[#f0f0f0] justify-center">
    <Navbar />
    <Body />
    <Footer />
  </div>);
}

export default App
